import Navbar from '../src/Navbar/Navbar'
import './index.css'



function App() {
  return (
    <>
        <Navbar />
      </>
  );
}

export default App;
